import { LocalStorageItems } from 'utils/localStorage/localStorage';

export type OnboardingUser = Partial<{
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
  businessName: string;
  country: string;
  formattedAddress: string;
  state: string;
  areaOfSpecialisationId: string;
  areasOfPracticeIds: string[];
  roleIds: string[];
  sitesOfCareIds: string[];
  id: string;
}>;

export const rehydrationKeys: LocalStorageItems[] = [
  LocalStorageItems.id,
  LocalStorageItems.firstName,
  LocalStorageItems.lastName,
  LocalStorageItems.email,
  LocalStorageItems.zipCode,
  LocalStorageItems.businessName,
  LocalStorageItems.country,
  LocalStorageItems.formattedAddress,
  LocalStorageItems.state,
  LocalStorageItems.areaOfSpecialisationId,
  LocalStorageItems.areasOfPracticeIds,
  LocalStorageItems.roleIds,
  LocalStorageItems.sitesOfCareIds,
];
