import { gql } from '@apollo/client';

export const getCompanies = gql`
  query getCompanies {
    getCompanies {
      description
      id
      businessUnitsNumber
      businessUnitAdminsNumber
      employeesNumber
      name
      logoUrl
      areasOfSpecialisationIds
      areasOfPracticeIds
      weighting
      contactUrl
    }
  }
`;
