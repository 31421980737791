import styled from 'styled-components';
import { defaultTheme } from 'constants/theme/defaultTheme';

export const Styles = {
  FullScreen: styled.div`
    z-index: 1;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
    transition-delay: 0.1s;
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  `,
  TextContainer: styled.div`
    margin-bottom: ${defaultTheme.spacing(4)};
    text-align: center;
  `,
  Dialog: styled.div`
    width: 80%;
    max-width: 350px;
    padding: ${defaultTheme.spacing(8)};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: ${defaultTheme.colors.white};
    opacity: 1;
  `,
};
