import React, { FC } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { fontFamily, getSpacing, breakpoints } from 'stylesheet';
import { logButtonTapEvent } from 'services/analytics/helpers';
import { defaultTheme } from 'constants/theme/defaultTheme';

interface ButtonLinkAttrs {
  href?: string;
  to?: string;
  margin?: string;
  block?: boolean;
  minWidth?: number;
  large?: boolean;
  medium?: boolean;
  smallPadding?: boolean;
  isOnboarding?: boolean;
}

const StyleButton = styled.button<ButtonLinkAttrs>`
  font-family: ${fontFamily.main};
  padding: ${getSpacing(3)} ${getSpacing(6)};
  cursor: ${props => (props.disabled === true ? 'default' : 'pointer')};
  border: none;
  border-radius: ${({ theme }) => theme.buttonBorderRadius};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, disabled }) =>
    disabled === true ? theme.colors.darkGrey : theme.colors.healthBlue};
  transition: background-color 0.3s ease-in-out;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${props =>
    props.disabled === true &&
    css`
      pointer-events: none;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.block &&
    css`
      width: 100%;
    `};
  ${props =>
    props.medium &&
    css`
      font-size: 16px;
    `}
  ${props =>
    props.large &&
    css`
      font-size: 18px;
    `}
  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
  ${props =>
    props.smallPadding &&
    css`
      padding: ${defaultTheme.spacing(2)};
    `}
  ${props =>
    props.isOnboarding &&
    `width: ${breakpoints.extraSmall};
    @media (max-width: ${breakpoints.small}) {
      width: 320px;
    }`}
`;

interface Button extends ButtonLinkAttrs, Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as'> {
  text: string;
  type?: 'button' | 'reset' | 'submit' | undefined;
  loading?: boolean;
  testID?: string;
}

export const Button: FC<Button> = ({ loading, text, large, onClick, testID, ...rest }) => {
  const onClickWithButtonTrackEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    logButtonTapEvent(
      {
        text,
      },
      window.location.pathname,
    );
    onClick && onClick(event);
  };

  return (
    <StyleButton data-testid={testID} large={large} onClick={onClickWithButtonTrackEvent} {...rest}>
      {!loading ? text : 'loading'}
    </StyleButton>
  );
};
