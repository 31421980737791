import styled from 'styled-components';
import { TextProps, getFontWeight } from 'atoms/Text/Text';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { breakpoints } from 'stylesheet';

export const Styles = {
  TextHeader: styled.h1<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-style: normal;
    font-weight: ${props => getFontWeight(props, 600)};
    font-size: 32px;
    line-height: 120%;
    color: ${props => props.color};
    width: ${props => props.width};
    margin-bottom: ${props => (props.defaultMargin ? '40px' : 0)};
    ${props => props.center && `text-align: center;`}
    @media (max-width: ${breakpoints.small}) {
      ${props => props.responsive && { fontSize: '18px', lineHeight: '150%' }}
    }
  `,
  TextH1: styled.h1<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 600)};
    font-size: 24px;
    line-height: 150%;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
    @media (max-width: ${breakpoints.small}) {
      ${props => props.responsive && { fontSize: '18px', lineHeight: '150%' }}
    }
  `,
  TextH2: styled.h2<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 600)};
    font-size: 18px;
    line-height: 150%;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
  `,
  TextH3: styled.h3<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 600)};
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.color};
    ${props => props.center && `text-align: center;`}
  `,
  TextBody1: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 500)};
    font-size: 18px;
    line-height: 150%;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
  `,
  TextBody2: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 400)};
    font-size: 18px;
    line-height: 150%;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
    @media (max-width: ${breakpoints.small}) {
      ${props => props.responsive && { fontSize: '16px', lineHeight: '150%' }}
    }
  `,
  TextBody3: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 400)};
    font-size: 16px;
    line-height: 150%;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
  `,
  TextCaption2: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-style: normal;
    font-weight: ${props => getFontWeight(props, 500)};
    font-size: 14px;
    line-height: 150%;
    width: ${props => props.width};
    margin-bottom: ${props => (props.defaultMargin ? `${defaultTheme.spacing(2)}` : 0)};
    color: ${props => props.color};
    ${props => props.center && `text-align: center;`}
  `,
  TextButton2: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 500)};
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
  `,
  TextButton3: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-weight: ${props => getFontWeight(props, 600)};
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
  `,
  TextCaption3: styled.p<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-style: normal;
    font-weight: ${props => getFontWeight(props, 500)};
    font-size: 12px;
    line-height: 150%;
    margin-bottom: ${props => (props.defaultMargin ? `${defaultTheme.spacing(2)}` : 0)};
    color: ${props => props.color};
    width: ${props => props.width};
    ${props => props.center && `text-align: center;`}
  `,
  // used on the welcome page
  Display: styled.h1<TextProps>`
    font-family: ${defaultTheme.fontFamily};
    font-style: normal;
    font-weight: ${props => getFontWeight(props, 600)};
    font-size: 40px;
    line-height: 120%;
    color: ${props => props.color};
    width: ${props => props.width};
    margin-bottom: ${props => (props.defaultMargin ? '40px' : 0)};
    ${props => props.center && `text-align: center;`}
    @media (max-width: ${breakpoints.small}) {
      ${props => props.responsive && { fontSize: '32px', lineHeight: '120%' }}
    }
  `,
};
