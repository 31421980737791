import React from 'react';
import { useModal } from 'contexts/ModalProvider/ModalProvider';
import { Background, ModalPanel } from './styles';

export const Modal: React.FC = () => {
  const { modalContent, isOpen } = useModal();

  if (isOpen) {
    return (
      <Background>
        <ModalPanel>{modalContent}</ModalPanel>
      </Background>
    );
  }

  return null;
};
