import { useRouter } from 'next/router';
import React, { useContext, createContext, useState, useEffect } from 'react';
import { logScreenTrackingEvent } from 'services/analytics/helpers';

type HistoryContextProps = {
  routeHistory: string[];
};

export const HistoryContext = createContext<HistoryContextProps | null>(null);

export const HistoryProvider: React.FC = ({ children }) => {
  const [routeHistory, setRouteHistory] = useState<string[]>([]);
  const { asPath, events, pathname } = useRouter();

  const handleRouteChange = (url: string) => {
    const [path, query] = url.split('?');
    logScreenTrackingEvent({
      // from is the second last route in the routeHistory
      from: routeHistory[routeHistory.length - 2] || '',
      to: path,
      query,
    });
  };

  useEffect(() => {
    setRouteHistory([...routeHistory, pathname]);
    logScreenTrackingEvent({ to: window.location.pathname, from: '' });
    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    // if current route does not equal the latest item
    // in the routeHistory, add it to routeHistory state
    if (routeHistory[routeHistory.length - 1] && routeHistory[routeHistory.length - 1] !== asPath) {
      setRouteHistory([...routeHistory, asPath]);
    }
  }, [asPath]);

  return <HistoryContext.Provider value={{ routeHistory }}>{children}</HistoryContext.Provider>;
};

export const useHistory = (): HistoryContextProps => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error('must be in the HistoryProvider to access this');
  }

  return context;
};
