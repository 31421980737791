import { ApolloClient, ApolloLink, FetchResult, from, Observable } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { getUserCredentials } from 'services/auth';
import { AppSyncConfig } from '../../awsConfig';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { logError, CustomError } from 'services/logging';
import { cache } from './cache';

const url = AppSyncConfig.graphqlEndpoint as string;
const region = AppSyncConfig.region as string;

const auth = {
  type: AppSyncConfig.authenticationType,
  jwtToken: async () => {
    return (await getUserCredentials()) || '';
  },
} as AuthOptions;

// Strips out typename from payloads, forward is apollo function that calls next link in the chain
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // Linter disabled as data can literally be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map(data => {
    return data;
  });
});

export const onErrorLink = ({ graphQLErrors }: ErrorResponse): Observable<FetchResult> | void => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message && message.includes('Validation error')) {
        const error = new CustomError(message, 'GraphQLClientError');
        logError({ error, errorName: error.name, message });
      }

      if (message && message.includes('Cannot return')) {
        const error = new CustomError(message, 'GraphQLServerError');
        logError({ error, errorName: error.name, message });
      }
    });
  }
};

export const errorLink = onError(onErrorLink);

const link = from([
  cleanTypeName,
  createAuthLink({ url, region, auth }),
  errorLink,
  createSubscriptionHandshakeLink({ url, region, auth }),
]);

export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
  },
});
