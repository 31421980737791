import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
`;

export const ModalPanel = styled.div`
  position: relative;
  border-radius: 5px;
  z-index: 9;
  margin: 0 ${defaultTheme.spacing(4)};
`;
