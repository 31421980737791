// Anys disabled because overlay content can be anything
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, useCallback, FC } from 'react';
import { Overlay } from 'atoms/Overlay/Overlay';
import { useDisableDocumentScroll } from 'hooks/useDisableDocumentScroll/useDisableDocumentScroll';

type OverlayProps = React.ComponentProps<typeof Overlay>;

type OverlayContextProps = {
  showOverlay: (overlayProps: Omit<OverlayProps, 'show'>) => unknown;
  hideOverlay: () => void;
};

const OverlayContext = createContext<OverlayContextProps | null>(null);

const initialState: OverlayProps = {
  buttonText: 'Okay',
  text: '',
  onClick: () => null,
};
export const OverlayProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [overlayProps, setOverlayProps] = useState<OverlayProps>(initialState);
  useDisableDocumentScroll(isOpen);

  const showOverlay = useCallback((overlayProps: OverlayProps) => {
    setOverlayProps(overlayProps);
    setIsOpen(true);
  }, []);

  const hideOverlay = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <OverlayContext.Provider value={{ showOverlay, hideOverlay }}>
      {isOpen && <Overlay {...overlayProps} />}
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlay = (): OverlayContextProps => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error('must be in the OverlayProvider to access this');
  }

  return context;
};
