import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getAll } from 'firebase/remote-config';

export const featureFlags: Record<string, boolean> = {};

export const initFirebase = async (): Promise<void> => {
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  };

  initializeApp(firebaseConfig);
};

export const fetchAndActivateRemoteConfig = async (): Promise<void> => {
  const remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 0;
  remoteConfig.defaultConfig = {};

  await fetchAndActivate(remoteConfig);
  const parameters = getAll(remoteConfig);

  if (parameters) {
    Object.entries(parameters).forEach(async configValue => {
      const [key, entry] = configValue;
      featureFlags[key] = entry.asBoolean();
    });
  }
};
