import { getSpacing as defaultGetSpacing } from 'constants/spacing';
export const spacing = defaultGetSpacing;

export const defaultTheme = {
  fontFamily: "'Asap', sans-serif",
  boxShadow: '0px 4px 8px rgba(20, 37, 59, 0.08)',
  borderRadius: '8px',
  buttonBorderRadius: '24px',
  maxContentWidth: 1400,
  baseSpacingUnit: 4,
  colors: {
    backgroundGrey: '#F6F7F8',
    borderGrey: '#ECECEC',
    grey: '#7C8B98',
    white: '#FFFFFF',
    black: '#2F4058',
    tableRowBg: '#fbfbfb',
    activeButton:
      'linear-gradient(94.85deg, rgba(255, 255, 255, 0.17) 2.52%, rgba(30, 125, 185, 0) 49.86%, rgba(15, 99, 152, 0.31) 97.49%), #1E7DB9',
    alertRed: '#E43A64',
    greyLight: '#EFF0F5',
    blue: '#1E7DB9',
    disabledGrey: '#A0AEB5',
    blueGrey: '#BCD8E3',
    deepBlue: '#212240',
    darkGrey: '#838394',
    snowGrey: '#F6F6F7',
    healthBlue: '#009EFF',
    secondaryGrey: '#A6A7B3',
    separator: '#EDEDF0',
    blueAccent: '#3C64B1',
    darkBlue: '#14548C',
    whiteSmoke: '#F2F7F8',
    deepGrey: '#575E70',
    activeTabOnDark: '#25415F',
    brightBlue: '#3BE5FF',
  },
  spacing,
};
