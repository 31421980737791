import React, { useEffect, useState } from 'react';
import GlobalStyles from 'components/GlobalStyles';
import flattenMessages from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import App, { AppContext, AppInitialProps, AppProps as NextAppProps } from 'next/app';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { AuthProvider } from 'contexts/AuthProvider/AuthProvider';
import * as logging from 'services/logging';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'services/apollo/client';
import { initFirebase, fetchAndActivateRemoteConfig } from 'services/featureFlags';
import { ModalProvider } from 'contexts/ModalProvider/ModalProvider';
import { OnboardingProvider } from 'contexts/OnboardingProvider/OnboardingProvider';
import { initAnalytics } from 'services/analytics/helpers';
import { withRouter } from 'next/router';
import { AlgoliaIndexes } from 'models/products';
import { InstantSearch } from 'react-instantsearch-core';
import { searchClient } from 'services/algolia';
import { CompanyProvider } from 'contexts/CompanyProvider/CompanyProvider';
import { OverlayProvider } from 'contexts/OverlayProvider/OverlayProvider';
import { HistoryProvider } from 'contexts/HistoryProvider/HistoryProvider';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { NextComponentType } from 'next';
import { AlgoliaProduct } from 'models/products';

type AppProps<P> = {
  pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

logging.init();

const locales = {
  en: flattenMessages(enMessages),
};

export type CustomPageProps = {
  hit?: AlgoliaProduct;
};

const MyApp: NextComponentType<AppContext, AppInitialProps, AppProps<CustomPageProps>> = ({
  pageProps,
  Component,
}) => {
  const [initComplete, setInitComplete] = useState(false);

  useEffect(() => {
    const init = async () => {
      await initAnalytics();
      await initFirebase();
      await fetchAndActivateRemoteConfig();
      setInitComplete(true);
    };

    init();
  }, []);

  return (
    <HistoryProvider>
      <AuthProvider>
        {pageProps && pageProps.hit && (
          <NextSeo
            title={`${pageProps.hit.productName} | vTail Healthcare`}
            description={pageProps.hit.productDescription}
            openGraph={{
              title: `${pageProps.hit.productName} | vTail Healthcare`,
              description: pageProps.hit.productDescription,
              images: [
                {
                  url: pageProps.hit.skuImage,
                  width: 800,
                  height: 600,
                  alt: pageProps.hit.productName,
                  type: 'image/jpeg',
                },
              ],
              site_name: 'vTail',
            }}
            twitter={{
              handle: '@vtailapp',
              site: '@vtailapp',
              cardType: 'summary_large_image',
            }}
          />
        )}
        <GlobalStyles />
        <ApolloProvider client={apolloClient}>
          <IntlProvider locale="en" messages={locales.en}>
            <ThemeProvider theme={defaultTheme}>
              <CompanyProvider>
                <OverlayProvider>
                  <ModalProvider>
                    <InstantSearch searchClient={searchClient} indexName={AlgoliaIndexes.Products}>
                      <OnboardingProvider>
                        <Head>
                          <title>vTail</title>
                        </Head>
                        {initComplete ? <Component {...pageProps} /> : <></>}
                      </OnboardingProvider>
                    </InstantSearch>
                  </ModalProvider>
                </OverlayProvider>
              </CompanyProvider>
            </ThemeProvider>
          </IntlProvider>
        </ApolloProvider>
      </AuthProvider>
    </HistoryProvider>
  );
};

MyApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default withRouter(MyApp);
