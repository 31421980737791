export enum AlgoliaIndexes {
  HCPCS = 'hcpcs',
  Products = 'products',
  Companies = 'companies',
  Categories = 'categories',
}

export type AlgoliaProduct = {
  objectID: string;
  productName: string;
  categories: string[];
  productDescription: string;
  companyName: string;
  brandName?: string[];
  areaOfSpecialisation: string;
  areaOfSpecialisationId: string;
  onVtail: boolean;
  skus?: SKU[];
  companyImage: string;
  companyId: string;
  areasOfPractice?: string[];
  areasOfPracticeIds?: string[];
  website: string;
  references?: string;
  ifu?: string;
  datasheet?: string;
  additionalDocs?: string[];
  stomaOpeningSize?: string;
  flangeSize?: string;
  pouchSize?: string;
  diameter?: string;
  color?: string[];
  additionalFeatures?: string[];
  urlSlug: string;
} & SKU;

export type SKU = {
  sku?: string[];
  hcpcs?: string[];
  productShape?: string[];
  orderUnits?: string;
  skuName: string;
  unitOfMeasurement?: string[];
  skuFeatures?: string;
  skuImage: string;
  urlSlug: string;
};
