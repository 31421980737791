import { makeVar, Reference, TypePolicies } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { ClinicalAttribute, ClinicalAttributeWithAOS } from 'models/global';
import { AreaOfSpecialisation } from '../../../../../types/graphql';

export const rolesVar = makeVar<Record<string, ClinicalAttribute>>({});
export const areasOfPracticeVar = makeVar<Record<string, ClinicalAttributeWithAOS>>({});
export const sitesOfCareVar = makeVar<Record<string, ClinicalAttribute>>({});
export const areasOfSpecialisationVar = makeVar<Record<string, AreaOfSpecialisation>>({});

export const queryTypePolicies: TypePolicies = {
  Query: {
    fields: {
      getRoles: {
        read(data, { readField }) {
          // Save manipulated data to reactive variable
          rolesVar(convertRefsArrayToObject(data, readField));
          // Return server data to cache
          return data;
        },
      },
      getSitesOfCare: {
        read(data, { readField }) {
          sitesOfCareVar(convertRefsArrayToObject(data, readField));
          return data;
        },
      },
      getAreasOfPractice: {
        read(data, { readField }) {
          areasOfPracticeVar(convertRefsArrayToObject(data, readField, true));
          return data;
        },
      },
      getAreasOfSpecialisation: {
        read(data, { readField }) {
          areasOfSpecialisationVar(convertRefsArrayToObject(data, readField, false, true));
          return data;
        },
      },
    },
  },
};

const convertRefsArrayToObject = (
  data: Reference[] | undefined,
  readField: ReadFieldFunction,
  isAop?: boolean,
  isAos?: boolean,
) => {
  if (data) {
    const sortedData = data.reduce((acc, item) => {
      // Cache returns a ref object so convert it to actual object using readField
      const id = readField('id', item) as string;
      const name = readField('name', item);

      if (isAop) {
        const areaOfSpecialisationId = readField('areaOfSpecialisationId', item);
        return { ...acc, [id]: { id, name, areaOfSpecialisationId } };
      }

      if (isAos) {
        const siteOfCareIds = readField('siteOfCareIds', item);
        const areaOfPracticeIds = readField('areaOfPracticeIds', item);
        return { ...acc, [id]: { id, name, siteOfCareIds, areaOfPracticeIds } };
      }

      return { ...acc, [id]: { id, name } };
    }, {});
    return sortedData;
  }
};
