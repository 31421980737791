import React from 'react';
import { Styles } from './styles';
import { Text } from 'atoms/Text/Text';
import { Button } from 'atoms/Button/Button';
import { TEST_IDS } from 'constants/testIds';

export interface OverlayProps {
  text: string;
  buttonText: string;
  onClick: () => unknown;
}

export const Overlay: React.FC<OverlayProps> = ({ buttonText, onClick, text }) => {
  return (
    <Styles.FullScreen>
      <Styles.Dialog>
        <Styles.TextContainer>
          <Text.h2 data-testid={TEST_IDS.overlayText}>{text}</Text.h2>
        </Styles.TextContainer>
        <Button testID={TEST_IDS.overlayButton} text={buttonText} onClick={onClick} />
      </Styles.Dialog>
    </Styles.FullScreen>
  );
};
