import Router from 'next/router';
import { Auth, CognitoUser, SignUpParams } from '@aws-amplify/auth';
import { logError } from 'services/logging';
import { PATHS } from 'constants/paths';
import { CognitoUserAttributes, forgotPasswordSubmitType, USER_GROUPS } from './types';

export type SignInResponse = {
  code: string;
  challengeName: string;
  message: string;
  attributes: Record<string, unknown>;
} & CognitoUser;

export type CognitoError = Error & {
  code?: string;
  message?: string;
};

export const signIn = async (email: string, password: string): Promise<SignInResponse> => {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    logError({ error, errorName: 'SignInError', message: 'signIn' });
    throw error;
  }
};

export const signUp = async (params: SignUpParams) => {
  return await Auth.signUp(params);
};

export const setNewPassword = async (
  user: CognitoUser,
  newPassword: string,
): Promise<CognitoUser | undefined> => {
  try {
    return await Auth.completeNewPassword(user, newPassword);
  } catch (error) {
    logError({ error, errorName: 'SetNewPasswordError', message: 'setNewPassword' });
  }
};

export const isUserAuthenticated = async (): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const getAuthenticatedUser = async (): Promise<CognitoUser | undefined> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    logError({ error, errorName: 'GetAuthenticatedUserError', message: 'getAuthenticatedUser' });
    Router.push(PATHS.LOGIN);
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut();
    Router.push(PATHS.LOGIN);
  } catch (error) {
    logError({ error, errorName: 'SignOutError', message: 'signOut' });
  }
};

export const getUserCredentials = async (): Promise<string | undefined> => {
  try {
    const userCredentials = await Auth.currentSession();
    return userCredentials.getIdToken().getJwtToken();
  } catch (error) {
    logError({ error, errorName: 'GetUserCredentialsError', message: 'getUserCredentials' });
  }
};

export const getCurrentUser = async () => {
  try {
    const credentials = await Auth.currentUserInfo();
    return credentials?.attributes;
  } catch (error) {
    logError({ error, errorName: 'GetCurrentUserError', message: 'getCurrentUser' });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserGroup = async (user: CognitoUser): Promise<string | any> => {
  try {
    const groups = await user.getSignInUserSession()?.getIdToken().payload['cognito:groups'];
    return groups[0];
  } catch (error) {
    logError({ error, errorName: 'GetUserGroupError', message: 'getUserGroup' });
  }
};

export const getCurrentUserWithUserGroup = async (): Promise<
  [string, string | undefined] | undefined
> => {
  try {
    const credentials = await Auth.currentUserPoolUser();
    const userGroup = await getUserGroup(credentials);
    const user = credentials?.attributes;
    return [user, userGroup];
  } catch (error) {
    logError({
      error,
      errorName: 'GetCurrentUserWithUserGroupError',
      message: 'getCurrentUserWithUserGroup',
    });
    Router.push(PATHS.LOGIN);
  }
};

export const getCurrentUserGroup = async (): Promise<string | undefined> => {
  try {
    const credentials = await Auth.currentUserPoolUser();
    const userGroup = await getUserGroup(credentials);
    return userGroup;
  } catch (error) {
    logError({ error, errorName: 'GetCurrentUserGroupError', message: 'getCurrentUserGroup' });
    Router.push(PATHS.LOGIN);
  }
};

export const autoLogin = async (): Promise<void> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const group = await getUserGroup(user);
      if (group === USER_GROUPS.BUSINESS_UNIT_ADMIN) Router.push(PATHS.EMPLOYEES);
      if (group === USER_GROUPS.VTAIL_ADMIN) Router.push(PATHS.ADMIN_COMPANIES);
    }
  } catch (error) {
    logError({ error, errorName: 'AutoLoginError', message: 'autoLogin' });
    return undefined;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forgotPassword = async (username: string): Promise<any> => {
  try {
    return await Auth.forgotPassword(username);
  } catch (error) {
    logError({ error, errorName: 'ForgotPasswordError' });
    throw error;
  }
};

export const forgotPasswordSubmit = async (payload: forgotPasswordSubmitType): Promise<void> => {
  await Auth.forgotPasswordSubmit(payload.username, payload.code, payload.password);
};

export const getUserAttributes = async (): Promise<CognitoUserAttributes> => {
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes;
};

export const getUserId = async (): Promise<string | undefined> => {
  const user = await Auth.currentAuthenticatedUser();
  return user?.username;
};
