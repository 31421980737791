export type CognitoUserAttributes = {
  email: string;
  given_name: string;
  family_name: string;
  email_verified: string;
  emailVerified: string;
};

export type forgotPasswordSubmitType = {
  username: string;
  code: string;
  password: string;
};

export enum USER_GROUPS {
  BUSINESS_UNIT_ADMIN = 'business-unit-admins',
  EMPLOYEE = 'employees',
  CLINICIAN = 'clinicians',
  VTAIL_ADMIN = 'vtail-admins',
}
