import { getSpacing as defaultGetSpacing } from 'constants/spacing';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { css } from 'styled-components';

/**
 * @deprecated should use defaultTheme.spacing
 */
export const getSpacing = defaultGetSpacing;

export const breakpoints = {
  extraSmall: '400px',
  small: '600px',
  medium: '900px',
  large: '1200px',
  extraLarge: '1536px',
};

/**
 * @deprecated please use the Text component
 */
export const fontFamily = {
  main: `'Asap', sans-serif`,
  code: 'Monospace',
};

/**
 * @deprecated please use the Text component
 */
export const typography = {
  main: css`
    font-family: ${fontFamily.main};
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    color: ${defaultTheme.colors.deepBlue};
  `,
  bold: css`
    font-weight: bold;
  `,
  light: css`
    font-weight: lighter;
  `,
  small: css`
    font-size: 14px;
  `,
  h1: css`
    font-family: ${fontFamily.main};
    font-weight: bold;
    font-size: 24px;
  `,
  code: css`
    font-family: ${fontFamily.code};
    color: ${defaultTheme.colors.deepBlue};
  `,
};
