import { defaultTheme } from 'constants/theme/defaultTheme';
import { createGlobalStyle } from 'styled-components';
import { typography } from 'stylesheet';

// We are resetting base HTML styles, using HTML tags is OK here
// stylelint-disable
const GlobalStyles = createGlobalStyle`
    /* This is the Eric Meyer reset: https://cssreset.com/scripts/eric-meyer-reset-css/ */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    b {
        font-weight: 600;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    /* This is our custom rules, they are here because most project need them */
    * {
        box-sizing: border-box;
    }
    html {
        ${typography.main};
    }
    body, #__next {
        background-color: ${defaultTheme.colors.white};
    }
    input {
        border: none;
        outline: none;
        ${typography.main};
    }
    a {
        text-decoration: none;
    }
    p, a, h1,h2,h3,h4,h5,label,button,div {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    select, label, textarea {
        ${typography.main};
    }
    .swiper {
        width: 100%
    }
    .markdown {
        strong {
            font-weight: 600;
        }
        li {
            list-style-type: disc;
            margin-left: ${defaultTheme.spacing(6)};
            margin-bottom: ${defaultTheme.spacing(2)};
        }
        em {
            font-style: italic;
        }
        p {
            margin-bottom: ${defaultTheme.spacing(2)};
        }
    }
`;

export default GlobalStyles;
