import { Styles } from './styles';
import { defaultTheme } from 'constants/theme/defaultTheme';
import React, { ReactElement } from 'react';

export const getFontWeight = ({ medium, bold, regular }: weightProps, deafultSize: number) => {
  if (medium) {
    return 500;
  }

  if (bold) {
    return 600;
  }

  if (regular) {
    return 400;
  }

  return deafultSize;
};

export declare type ValueOf<T> = T[keyof T];

type weightProps = {
  regular?: boolean | null | undefined;
  medium?: boolean | null | undefined;
  bold?: boolean | null | undefined;
};

export type TextProps = {
  children?: React.ReactNode | string;
  as?: 'span';
  color?: Exclude<ValueOf<typeof defaultTheme.colors>, string[]>;
  center?: boolean;
  defaultMargin?: boolean;
  responsive?: boolean;
  width?: string;
} & weightProps;

export const Text = () => {};

Text.display = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.Display {...rest}>{children}</Styles.Display>;
};

Text.header = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextHeader {...rest}>{children}</Styles.TextHeader>;
};

Text.h1 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextH1 {...rest}>{children}</Styles.TextH1>;
};

Text.h2 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextH2 {...rest}>{children}</Styles.TextH2>;
};

Text.h3 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextH3 {...rest}>{children}</Styles.TextH3>;
};

Text.body1 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextBody1 {...rest}>{children}</Styles.TextBody1>;
};

Text.body2 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextBody2 {...rest}>{children}</Styles.TextBody2>;
};

Text.body3 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextBody3 {...rest}>{children}</Styles.TextBody3>;
};

Text.caption2 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextCaption2 {...rest}>{children}</Styles.TextCaption2>;
};

Text.button2 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextButton2 {...rest}>{children}</Styles.TextButton2>;
};

Text.button3 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextButton3 {...rest}>{children}</Styles.TextButton3>;
};

Text.caption3 = ({ children, ...rest }: TextProps): ReactElement<TextProps> => {
  return <Styles.TextCaption3 {...rest}>{children}</Styles.TextCaption3>;
};
