import { TypeOfObjectKey } from 'utils/types/types';

export enum CustomEventName {
  'EmployeeCreated' = 'EmployeeCreated',
  'EmployeeUpdated' = 'EmployeeUpdated',
  'NewsFeedItemDraftSaved' = 'NewsFeedItemDraftSaved',
  'NewsfeedItemDraftPublished' = 'NewsfeedItemDraftPublished',
  'Admin_CompanyCreated' = 'Admin_CompanyCreated',
  'Admin_CompanyEdited' = 'Admin_CompanyEdited',
  'Admin_BusinessUnitCreated' = 'Admin_BusinessUnitCreated',
  'Admin_BusinessUnitAdminCreated' = 'Admin_BusinessUnitAdminCreated',
  'Web_Onboarding_Attributed' = 'Web_Onboarding_Attributed',
  'Web_Clinician_OnboardingCompleted' = 'Web_Clinician_OnboardingCompleted',
  'Web_Onboarding_Started' = 'Web_Onboarding_Started',
  'App_Link_SMS_Sent' = 'App_Link_SMS_Sent',
  'Product_Search_Click' = 'Product_Search_Click',
  'Product_Searched' = 'Product_Searched',
  'Product_Footer_Sign_Up' = 'Product_Footer_Sign_Up',
  'ProductTapped' = 'ProductTapped',
  'Product_Contact_Rep' = 'Product_Contact_Rep',
  'Product_Open_Document' = 'Product_Open_Document',
  'Product_Open_Related' = 'Product_Open_Related',
  'Product_Filter_Click' = 'Product_Filter_Click',
  'Product_Filter_AOS_Click' = 'Product_Filter_AOS_Click',
  'ReportProductProblem' = 'ReportProductProblem',
  'Install_Attributed' = 'Install_Attributed',
  'Newsfeed_Item_Archived' = 'Newsfeed_Item_Archived',
  'BranchGroupChatOnboarding' = 'BranchGroupChatOnboarding',
}

export type TrackingEvent = {
  name: CustomEventName;
  data?: Record<string, string | number | unknown>;
};

export type UserAttributeEvent = {
  userAttribute: UserAttributeType;
  value: string | string[];
};

// See MP reserved attributes:
// https://docs.mparticle.com/developers/sdk/ios/users/#reserved-attributes

export enum UserAttributeType {
  FirstName = '$FirstName',
  LastName = '$LastName',
  Email = 'Email',
  Group = 'Group',
  JobTitle = 'JobTitle',
  AreasOfPractice = 'AreasOfPractice',
  SitesOfCare = 'SitesOfCare',
  AreasOfSpecialisation = 'AreasOfSpecialisation',
  Roles = 'Roles',
  ZipCode = 'ZipCode',
  State = 'State',
  Attribution = 'Attribution',
  FormularyName = 'FormularyName',
  NotificationPermissions = 'NotificationPermissions',
  ShareUrl = 'ShareUrl',
  UserReferralAttribution = 'UserReferralAttribution',
  // Name used only for intercom web sdk, and key is case sensitive
  Name = 'name',
  GroupChatInvite = 'GroupChatInvite',
  AttributionType = 'AttributionType',
}

export type ButtonTapTrackingEvent = {
  text: string;
};

export type ScreenTrackingEvent = {
  from: string;
  to: string;
  query?: string;
};

type DataType = TypeOfObjectKey<TrackingEvent, 'data'>;

export const cleanDataAnalytics = <T extends object>(obj: T): DataType => {
  return Object.entries(obj).reduce(
    (acc: DataType, [key, value]: [string, string | number]): DataType => {
      if (typeof value === 'undefined') {
        return acc;
      }
      return { ...acc, [key]: value };
    },
    {},
  );
};
