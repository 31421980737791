export const awsConfig = {
  region: process.env.AMPLIFY_REGION,
  userPoolId: process.env.AMPLIFY_USERPOOLID,
  userPoolWebClientId: process.env.AMPLIFY_USERPOOL_WEBCLIENTID,
};

export const AppSyncConfig = {
  region: process.env.AMPLIFY_REGION,
  graphqlEndpoint: process.env.GRAPHQL_ENDPOINT,
  authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};
