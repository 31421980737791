// Anys disabled because modal content can be anything
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { useDisableDocumentScroll } from 'hooks/useDisableDocumentScroll/useDisableDocumentScroll';

type ModalContextProps = {
  isOpen: boolean;
  handleModal: (content?: any) => void;
  modalContent: any;
};

const ModalContext = createContext<ModalContextProps | null>(null);

export const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const handleModal = (content?: any) => {
    setIsOpen(!isOpen);
    setModalContent(content);
  };

  useDisableDocumentScroll(isOpen);

  return (
    <ModalContext.Provider value={{ isOpen, handleModal, modalContent }}>
      {children}
      <Modal />
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('must be in the ModalProvider to access this');
  }

  return context;
};
