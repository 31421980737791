import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';
import { OnboardingUser, rehydrationKeys } from './types';

export type OnboardingContextProps = {
  user: OnboardingUser;
  updateUser: (key: keyof OnboardingUser) => (value: string | string[]) => void;
};

export const OnboardingContext = createContext<OnboardingContextProps | null>(null);

export const OnboardingProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<OnboardingUser>({});

  useEffect(() => {
    const rehydrateState = () => {
      rehydrationKeys.forEach(key => {
        const value = localStorageClass.getItem(key);
        if (value) {
          setUser((prev: OnboardingUser) => ({
            ...prev,
            [key]: value,
          }));
        }
      });
    };

    rehydrateState();
  }, []);

  const updateUser = useCallback(
    (key: keyof OnboardingUser) => (value: string | string[]) => {
      setUser((prev: OnboardingUser) => ({
        ...prev,
        [key]: value,
      }));
      localStorageClass.setItem(LocalStorageItems[key], value);
    },
    [],
  );

  return (
    <OnboardingContext.Provider value={{ user, updateUser }}>{children}</OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingContextProps => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error('must be in the OnboardingProvider to access this');
  }

  return context;
};
